// create shop cart store
import { acceptHMRUpdate } from "pinia";
import type { Cart, CartTextileItem } from "@/types/cart";
export const useCartStore = defineStore(
	"Cart",
	() => {
		const scmApi = useSCMApi();
		const abmApi = useABMApi();
		const { platformId } = storeToRefs(usePlatformStore());
		const cart = ref<Cart | null>(null);
		const cartProducts = ref<CartTextileItem[]>();
		const cartProductsGroupByWarehouse = ref<CartTextileItem[][]>();
		const cartSamples = ref<CartTextileItem[]>();
		const numOfTextileItems = ref<number | null>(null); // number of textile_items in cart, which is used in header badge on Cart
		const numOfProducts = ref<number>(0);
		const numOfSamples = ref<number>(0);

		async function getCartData() {
			const { response, error } = await scmApi.getUserActiveCartCart(platformId.value);

			if (!error.value) {
				cart.value = response.value?.data as Cart;

				if (cart.value.length > 0) {
					const cartList = cart.value?.[0]?.cart_textile_items;
					numOfTextileItems.value = cart.value?.[0]?.cart_textile_items?.length;
					cartProducts.value = cartList.filter((item: any) => item.sample === false);
					numOfProducts.value = cartProducts.value?.length ?? 0;
					for (let i = 0; i < cartProducts.value!.length; i++) {
						const { response } = await abmApi.viewWarehouse(cartProducts.value![i].warehouse_id);
						cartProducts.value![i].warehouse_name = response.value?.data?.warehouse_name || "";
					}
					const result: any = cartProducts.value.reduce((x: any, y: any) => {
						(x[y.warehouse_id] = x[y.warehouse_id] || []).push(y);
						return x;
					}, {});
					cartProductsGroupByWarehouse.value = Object.keys(result).map(key => [result[key]]);
					cartSamples.value = cartList.filter((item: any) => item.sample === true);
					numOfSamples.value = cartSamples.value!.length;
				} else {
					numOfTextileItems.value = 0;
					numOfProducts.value = 0;
					numOfSamples.value = 0;
				}
				return "Success";
			} else {
				throw new Error("Failed");
			}
		}

		function clearCartData() {
			cart.value = null;
			cartProducts.value = [];
			cartSamples.value = [];
			numOfProducts.value = 0;
			numOfSamples.value = 0;
			numOfTextileItems.value = 0;
		}

		function getProductsPrice() {
			let sum = 0;
			if (cartProducts.value && cartProducts.value?.length > 0) {
				for (let i = 0; i < cartProducts.value.length; i++) {
					sum = sum + cartProducts.value[i].meter_quantity * cartProducts.value[i].unit_price;
				}
			}
			return sum;
		}

		function getSamplesPrice() {
			let sum = 0;
			if (cartSamples.value && cartSamples.value.length > 0) {
				sum = sum + 3.5 * cartSamples.value?.length;
			}
			return sum;
		}

		function getTotalPrice() {
			let sum = 0;
			if (cartProducts.value && cartProducts.value?.length > 0) {
				for (let i = 0; i < cartProducts.value.length; i++) {
					sum = sum + cartProducts.value[i].meter_quantity * cartProducts.value[i].unit_price;
				}
			}
			if (cartSamples.value && cartSamples.value.length > 0) {
				sum = sum + 3.5 * cartSamples.value?.length;
			}
			return sum;
		}

		async function setCartProcessed(cartId: string) {
			const { error } = await scmApi.setProcessedCart(cartId as string);
			if (!error.value) {
				return "Success";
			} else {
				return Promise.reject(new Error("Failed"));
			}
		}

		async function checkTextileItemAlreadyInCart(item_id: string, isSample: boolean) {
			const { response } = await scmApi.isTextileItemInCartCart({
				textile_item_id: item_id,
				platform_id: platformId.value,
				sample: isSample,
			});
			if (response.value?.data) {
				return "Success";
			} else {
				return "Failed";
			}
		}

		return {
			cart,
			cartProducts,
			cartProductsGroupByWarehouse,
			cartSamples,
			numOfProducts,
			numOfSamples,
			numOfTextileItems,
			getCartData,
			clearCartData,
			getProductsPrice,
			getSamplesPrice,
			getTotalPrice,
			setCartProcessed,
			checkTextileItemAlreadyInCart,
		};
	},
	{
		persist: {
			storage: piniaPluginPersistedstate.sessionStorage(),
		},
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
