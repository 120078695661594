
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45usVS9jrCBG8qMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/about-us.vue?macro=true";
import { default as authEKg5mhO8L4Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/auth.vue?macro=true";
import { default as _91article_93F3tnnuzWxtMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/[article].vue?macro=true";
import { default as indexOPhnVEHGnUMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/index.vue?macro=true";
import { default as _91slug_934fBXkAjwILMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/[slug].vue?macro=true";
import { default as indexHMtJmXGsyxMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/index.vue?macro=true";
import { default as contact_45usGtW1TBUM2nMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/contact-us.vue?macro=true";
import { default as how_45to_45sellpmmORz6h2UMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/how-to-sell.vue?macro=true";
import { default as indexWy0cFf8jJIMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/index.vue?macro=true";
import { default as _91merchant_93dcxSqmIZYNMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/[merchant].vue?macro=true";
import { default as index6Z0HAnUaCaMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/index.vue?macro=true";
import { default as my_45cartRIYXCYJ1AmMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/my-cart.vue?macro=true";
import { default as order_45confirmationvmPv4gkNX7Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/order-confirmation.vue?macro=true";
import { default as _91id_930z3CkXPOB1Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/[id].vue?macro=true";
import { default as indexa9kZb3h1fOMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/index.vue?macro=true";
import { default as privacy_45policyzcKFcw4Gr0Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/privacy-policy.vue?macro=true";
import { default as quality_45guidelinesGfFZWidy8PMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/quality-guidelines.vue?macro=true";
import { default as secured_45payment_45methodselOFmpn2TgMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/secured-payment-methods.vue?macro=true";
import { default as shipping_45return_45policiesbkbFMLY8j7Meta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/shipping-return-policies.vue?macro=true";
import { default as terms_45of_45servicesH0E5kpXQkKMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/terms-of-services.vue?macro=true";
import { default as _91id_93OddcUIWlkzMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/[id].vue?macro=true";
import { default as indexi67CVw1UcZMeta } from "/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/about-us.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authEKg5mhO8L4Meta || {},
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/auth.vue")
  },
  {
    name: "blog-article",
    path: "/blog/:article()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/[article].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/blog/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/collections/index.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/contact-us.vue")
  },
  {
    name: "how-to-sell",
    path: "/how-to-sell",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/how-to-sell.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/index.vue")
  },
  {
    name: "merchants-merchant",
    path: "/merchants/:merchant()",
    meta: _91merchant_93dcxSqmIZYNMeta || {},
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/[merchant].vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/merchants/index.vue")
  },
  {
    name: "my-cart",
    path: "/my-cart",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/my-cart.vue")
  },
  {
    name: "order-confirmation",
    path: "/order-confirmation",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/order-confirmation.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/orders/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/privacy-policy.vue")
  },
  {
    name: "quality-guidelines",
    path: "/quality-guidelines",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/quality-guidelines.vue")
  },
  {
    name: "secured-payment-methods",
    path: "/secured-payment-methods",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/secured-payment-methods.vue")
  },
  {
    name: "shipping-return-policies",
    path: "/shipping-return-policies",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/shipping-return-policies.vue")
  },
  {
    name: "terms-of-services",
    path: "/terms-of-services",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/terms-of-services.vue")
  },
  {
    name: "textile",
    path: "/textiles/:id()",
    meta: _91id_93OddcUIWlkzMeta || {},
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/[id].vue")
  },
  {
    name: "textiles",
    path: "/textiles",
    component: () => import("/home/runner/work/Frontend-MKTPLC/Frontend-MKTPLC/pages/textiles/index.vue")
  }
]