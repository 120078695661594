import { defineStore } from "pinia";
import type {
	LightSource,
	ColorSpace,
	StaticProductData,
	Construction as ConstructionOption,
} from "@/types/staticProductData";
import type { Application, FiberLayer } from "@/types/products";

export const useStaticProductDataStore = defineStore("staticProductData", () => {
	const { getStaticDataGeneral } = usePMApi();
	const { $sentry } = useNuxtApp();
	const staticProductData = ref<StaticProductData | null>(null);

	const constructionOptions = ref<any[]>([]);
	const fiberContentOptions = ref<any[]>([]);

	async function updateStaticProductData() {
		if (!staticProductData.value) {
			try {
				const { response, error } = await getStaticDataGeneral<StaticProductData>({ requestFunc: "$fetch" });

				if (error.value) throw error.value;

				if (response.value?.data) {
					staticProductData.value = response.value.data;

					constructionOptions.value = flatTreeOptions(staticProductData.value?.Constructions || []);
					fiberContentOptions.value = flatTreeOptions(staticProductData.value?.Fibers || []);
				}
			} catch (error: any) {
				$sentry.captureException(`Cannot fetch static product data, ${error.message}`);
			}
		}
	}

	const { listLightSources, listColorSpaces } = usePMApi();

	const lightSources = ref<LightSource[]>();

	async function updateLightSources() {
		try {
			const { response, error } = await listLightSources<LightSource[]>({});

			if (error.value) throw error.value;

			if (response.value?.data) {
				lightSources.value = response.value.data;
			}
		} catch (error: any) {
			lightSources.value = [];
			$sentry.captureException(`Cannot fetch light_sources, ${error?.message?.toString()}`);
		}
	}

	const colorSpaces = ref();

	async function updateColorSpaces() {
		try {
			const { response, error } = await listColorSpaces<ColorSpace[]>({});

			if (error.value) throw error.value;

			if (response.value?.data) {
				colorSpaces.value = response.value.data;
			}
		} catch (error) {
			colorSpaces.value = [];
			$sentry.captureException(`Cannot fetch color_spaces, ${error?.message?.toString()}`);
		}
	}

	const flatTreeOptions = (options: any[], level = 1): any => {
		return (
			options
				.map(option => {
					if (option.children.length <= 0) {
						return {
							...option,
							level,
						};
					} else {
						return [
							{
								...option,
								level,
							},
							...flatTreeOptions(option.children, level + 1),
						];
					}
				})
				.flat(Infinity)
				.map(option => ({ ...option, title: option.text, value: option.id })) || []
		);
	};

	const getSelectedConstructionIds = (
		id: string | undefined,
		selections: string[] = [],
		level: number | null = null
	): any => {
		if (!id) return [];
		else {
			const currConstruction: (ConstructionOption & { level?: number }) | undefined = constructionOptions.value.find(
				(option: ConstructionOption) => option.id === id
			);

			selections.unshift(id);
			if (!currConstruction?.parent_id || (level && currConstruction?.level && level >= currConstruction?.level))
				return selections;
			else {
				return getSelectedConstructionIds(currConstruction.parent_id, selections, level);
			}
		}
	};

	const displayFiberContent = (fiber_layers: FiberLayer[]): string[] => {
		const tree = generateFibersSelectionTree(fiber_layers);

		return tree
			.sort((a, b) => b.selection.percentage - a.selection.percentage)
			.map(item => {
				const text = item.selectionTree
					.filter((tree: any) => tree.selection.fiber_id)
					.map((v: any) => {
						const fiberText = fiberContentOptions.value.find(option => option.id === v.selection.fiber_id)?.text || "";
						return `${fiberText}`;
					});

				return `${item.selection.percentage}% ${text[1] || text[0]}`;
			});
	};

	const generateFibersSelectionTree = (fibers: FiberLayer[]) => {
		return (
			fibers?.map((fiber: FiberLayer) => {
				const options: any = [];
				const optionsForCurrent = fiberContentOptions.value.filter(item => item.parent_id === fiber.fiber_id);
				if (optionsForCurrent.length > 0) {
					options.unshift({ selection: { fiber_id: undefined }, options: optionsForCurrent });
				}
				return { selection: fiber, selectionTree: findParentOptions(fiber, options) };
			}) || []
		);
	};

	const findParentOptions = (fiber: FiberLayer, options: any[] = []): any => {
		const curr = fiberContentOptions.value.find(item => item.id === fiber.fiber_id);
		if (curr?.parent_id) {
			const parent = fiberContentOptions.value.find(item => item.id === curr.parent_id);
			const optionsForParent = fiberContentOptions.value.filter(item => item.parent_id === parent?.id);
			options.unshift({ selection: fiber, options: optionsForParent });
			return findParentOptions({ ...fiber, fiber_id: curr.parent_id, percentage: 0 }, options);
		} else {
			const rootOptions = fiberContentOptions.value.filter(item => !item.parent_id);

			options.unshift({ selection: fiber, options: rootOptions });
			return options;
		}
	};

	interface ApplicationObject {
		market?: string;
		industry?: string;
		category?: string;
		product?: string;
	}

	const generateApplication = (application: Application, applicationObj: ApplicationObject = {}): ApplicationObject => {
		const obj: ApplicationObject = useCloneDeep(applicationObj);

		if (!obj[application.type as "industry" | "market" | "category" | "product"]) {
			obj[application.type as "industry" | "market" | "category" | "product"] = application.text;

			if (
				application.parent_id &&
				(("parent_application" in application && application.parent_application) ||
					("grand_parent_application" in application && application.grand_parent_application) ||
					("great_grand_parent_application" in application && application.great_grand_parent_application))
			) {
				return generateApplication(
					application.parent_application ||
						application.grand_parent_application ||
						application.great_grand_parent_application,
					obj
				);
			}
		}

		return obj;
	};

	return {
		staticProductData,
		lightSources,
		colorSpaces,
		constructionOptions,
		fiberContentOptions,
		flatTreeOptions,
		updateStaticProductData,
		updateLightSources,
		updateColorSpaces,
		displayFiberContent,
		getSelectedConstructionIds,
		generateFibersSelectionTree,
		generateApplication,
	};
});
