import type {
	changeUsingRecoveryCodePasswordFormData,
	changePasswordFormData,
} from "@tengiva/services-api-layer/composables/useURMApi";
import type { User } from "@/types/user";

const COOKIE_OPTIONS = {
	maxAge: 86400 * 30,
	sameSite: true,
	secure: true,
};

const isTokenExpiredIn5Mins = (token: string) => {
	const jwtObject = parseJWT(token);

	const expInMilliseconds = jwtObject.exp * 1000;

	const dateExpireMinus5Minutes = new Date(expInMilliseconds);

	dateExpireMinus5Minutes.setMinutes(dateExpireMinus5Minutes.getMinutes() - 5);

	return dateExpireMinus5Minutes < new Date();
};

export type NormalLoginForm = {
	email: string;
	password: string;
	rememberMe: boolean;
	platform_id: string | null;
};

export type SSOLoginForm = {
	sso_partner: string;
	sso_credential: string;
	platform_id: string | null;
};

export type LoginForm = NormalLoginForm | SSOLoginForm;

export const useURM = () => {
	const { baseUrl } = useRuntimeConfig().public;
	const {
		loginAuth,
		userAuth,
		tokenAuth,
		recoverPassword,
		changePassword: changePasswordCall,
		changeUsingRecoveryCodePassword,
		viewUser,
		editUser: editUserCall,
		addIndividualMember,
	} = useURMApi();

	const login = async (formData: LoginForm, sso = false): Promise<User> => {
		if (sso === false) {
			throw new Error("This option is no longer supported from this platform");
		}

		const requestBody = {
			...formData,
		};

		const { response, error } = await loginAuth<{ user: User; token: string }>(requestBody);

		if (error.value) throw error.value;
		else {
			const userEmailCookie = useCookie<string>("user_email", {
				...COOKIE_OPTIONS,
			});
			const jwtTokenCookie = useCookie<string>("jwt_token", {
				...COOKIE_OPTIONS,
				sameSite: "lax",
			});
			if (!sso && (<NormalLoginForm>formData).rememberMe) userEmailCookie.value = (<NormalLoginForm>formData).email;
			jwtTokenCookie.value = response.value!.data.token;
			return response.value!.data.user;
		}
	};

	const refreshUser = async (): Promise<User | null> => {
		const jwtToken = useCookie<string>("jwt_token");
		const canRefreshUser = !!jwtToken.value;

		if (!canRefreshUser) return null;
		else {
			// Refresh token if it is close to be expired.
			if (jwtToken.value && isTokenExpiredIn5Mins(jwtToken.value)) {
				const { response, error } = await tokenAuth();
				if (error.value) throw error.value;
				jwtToken.value = response.value!.data;
			}

			// Finally, Refresh user with valid jwt token.
			const { response, error } = await userAuth<{ user: User }>();
			if (error.value) throw error.value;
			return response.value!.data.user;
		}
	};

	const logout = () => {
		const userEmailCookie = useCookie<boolean | null>("user_email");
		const jwtTokenCookie = useCookie<string | null>("jwt_token");
		userEmailCookie.value = null;
		jwtTokenCookie.value = null;
	};

	const requestResetPassword = async (email: string): Promise<void> => {
		const { error } = await recoverPassword({
			email,
			url: baseUrl,
		});
		if (error.value) throw error.value;
	};

	const resetPassword = async (formData: changeUsingRecoveryCodePasswordFormData) => {
		const { response, error } = await changeUsingRecoveryCodePassword<{ email: string }>(formData);

		if (error.value) throw error.value;

		return response.value!.data.email;
	};

	const editUser = async (info: any) => {
		const { error } = await editUserCall(JSON.stringify(info));
		if (error.value) throw error.value;
	};

	const changePassword = async (form: changePasswordFormData) => {
		const { error } = await changePasswordCall(form);
		if (error.value) throw error.value;
	};

	const viewUserById = async (id: string): Promise<User | null> => {
		const { response, error } = await viewUser<User | null>(id);
		if (error.value) throw error.value;

		return response.value?.data || null;
	};

	type NewIndividualForm = {
		email?: string;
		password?: string;
		first_name: string;
		last_name: string;
		phone: number;
		phone_ext: number;
		sso_partner?: string;
		sso_credential?: string;
	};
	const addIndividual = async (form: NewIndividualForm): Promise<User> => {
		const { response, error } = await addIndividualMember({
			user: form,
		});
		if (error.value) throw error.value;

		// Store token to cookie for auto sign in
		const jwtTokenCookie = useCookie<string>("jwt_token", {
			...COOKIE_OPTIONS,
		});
		jwtTokenCookie.value = response.value!.data.token;
		return response.value!.data.individual.user as User;
	};
	return {
		login,
		logout,
		refreshUser,
		requestResetPassword,
		resetPassword,
		editUser,
		changePassword,
		viewUserById,
		addIndividual,
	};
};
