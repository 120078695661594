import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type { filters } from "@/types/filters";
import type { Warehouse } from "@/types/addresses";
import type { Certification, Socials, MerchantProfile } from "@/types/merchant";
import type { CatalogTextileItem } from "@/types/products";

export const useMerchantStore = defineStore("merchant", () => {
	const { viewMerchantProfileMarketplace } = useOrgMApi();
	const { listProductsForMktplcMarketplace } = usePMApi();

	const { orderOptions } = storeToRefs(useFiltersStore());
	const { formatFiltersForEndpoint, formatFiltersForQueryParams } = useFiltersStore();

	const { platformId, plfm } = storeToRefs(usePlatformStore());
	const merchantFromApi = ref<MerchantProfile>();
	const merchantId = ref<string>("");

	const { $sentry } = useNuxtApp();

	const router = useRouter();

	interface Addr {
		isBusinessAddress: boolean;
		name: string;
		addressLine1: string;
		addressLine2: string;
		addressLine3: string;
	}

	const merchant = computed(
		(): {
			cover: string;
			name: string;
			description: string;
			businessNumber: string;
			taxId: string;
			certifications: Certification[];
			warehouses: Addr[];
			address: Addr;
			links: { label: string; url: string }[];
		} => {
			if (!merchantFromApi.value) return;

			const { imgixEcmOdcUrl } = storeToRefs(usePlatformStore());
			const ws = merchantFromApi.value.warehouses?.warehouses || [];
			const addresses = ws.map((w: Warehouse) => {
				return {
					isBusinessAddress: w.address.business_address,
					name: w.warehouse_name,
					addressLine1: `${w.address.address} ${w.address.address_additional || ""}`,
					addressLine2: `${w.address.city}, ${w.address.state.toUpperCase()}, ${w.address.zip.toUpperCase()}`,
					addressLine3: w.address.country_name,
				};
			}) as Addr[];

			const certs = merchantFromApi.value.certifications as Certification[];
			return {
				cover: ![null, undefined].includes(merchantFromApi.value?.details?.branding?.profile_banner_img_id)
					? `${imgixEcmOdcUrl.value}/organizations/${merchantFromApi.value?.details?.id}/my-business/${merchantFromApi.value?.details?.branding?.profile_banner_img_id}`
					: "https://storage.cloud.google.com/service-shrd-ecm-odc-prod/organizations/034e4c04-567b-4df2-adaf-b8fa27005f2e/my-business/420c8645-070a-47bd-b92d-399268fd398e",
				name: merchantFromApi.value?.details?.profile?.public_name,
				description: merchantFromApi.value?.details?.profile?.description,
				businessNumber: merchantFromApi.value.accounting?.business_number,
				taxId: merchantFromApi.value.accounting?.tax_regulation_number,
				certifications: certs ? certs.map((c: Certification) => c.certification.name) : [],
				warehouses: addresses.filter((w: Addr) => !w.isBusinessAddress),
				address: addresses.find((w: Addr) => w.isBusinessAddress),
				links: formatLinks(merchantFromApi.value?.details?.online),
			};
		}
	);

	// Helpers
	function formatLinks(online: Socials): { label: string; url: string }[] {
		if (!online) return [];

		const pre = "https://";
		const socials = [
			{ key: "website", prefix: "" },
			{ key: "social_linkedin", prefix: "www.linkedin.com/" },
			{ key: "social_twitter", prefix: "www.twitter.com/" },
			{ key: "social_facebook", prefix: "www.facebook.com/" },
			{ key: "social_instagram", prefix: "www.instagram.com/" },
			{ key: "social_pinterest", prefix: "www.pinterest.com/" },
		];

		const links = [] as { label: string; url: string }[];
		for (const social of socials) {
			const val = online[social.key] as string;
			if (!val || val === "-") continue;
			links.push({
				label: social.prefix + val.replace(pre, ""),
				url: val.includes(pre) ? social.prefix + val : pre + social.prefix + val,
			});
		}

		return links;
	}

	async function setMerchant(merchant_id: string) {
		merchantId.value = merchant_id;

		merchantFromApi.value = await fetchMerchant(merchantId.value);

		return {};
	}

	async function fetchMerchant(merchantId: string) {
		try {
			const { response, error } = (await viewMerchantProfileMarketplace(merchantId)) as Response<MerchantProfile>;

			if (error.value) throw error.value;

			if (response.value?.data) {
				return response.value.data;
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch viewMerchantProfileMarketplace (${err.data?.error.message})`);
		}
	}

	const products = ref<CatalogTextileItem[]>([]);
	const pagination = ref<Pagination>();

	const order = ref<string>("");
	const limit = ref<number>(21);
	const filters = ref<filters>({});

	async function setProducts(page: number = 1) {
		const fetchedData = await fetchProducts(merchantId.value, page);

		products.value = fetchedData?.products || [];
		pagination.value = fetchedData?.pagination;

		return {};
	}

	async function fetchProducts(merchantId: string, page: number) {
		try {
			const selectedOrder: OrderType = orderOptions.value?.find((option: OrderType) => option.value === order.value);
			const { response, error } = await listProductsForMktplcMarketplace<{
				pagination: Pagination;
				textile_items: CatalogTextileItem[];
				total_in_stock_inventory: number;
			}>(platformId.value, {
				limit: limit.value,
				page,
				order: selectedOrder?.order || {
					"platform_catalog_products.created": "DESC",
				},
				organization_id: merchantId,
				filters: {
					...formatFiltersForEndpoint(filters.value),
				},
			});

			if (error.value) throw error.value;

			if (response.value?.data) {
				return {
					products: response.value.data.textile_items,
					pagination: response.value.data.pagination,
				};
			}
		} catch (err: any) {
			$sentry.captureException(`Cannot fetch listProductsForMktplcMarketplace (${err.data?.error.message})`);
		}
	}

	function setOrder(o: string) {
		order.value = o;
	}

	function setLimit(l: number) {
		limit.value = l;
	}

	function setFilters(f: filters) {
		filters.value = f;

		router.push({ query: formatFiltersForQueryParams(filters.value) });
	}

	return {
		merchant,
		merchantFromApi,
		order,
		limit,
		products,
		pagination,
		filters,
		setMerchant,
		setProducts,
		setOrder,
		setLimit,
		setFilters,
	};
});
