import { useUserStore } from "@/store/modules/user";
import { useCartStore } from "@/store/modules/cart";

export default defineNuxtRouteMiddleware(async (to, _) => {
	// skip middleware on server
	if (import.meta.server) return;

	const userStore = useUserStore();
	const { getCartData } = useCartStore();
	const { user } = storeToRefs(userStore);
	const jwtToken = useCookie<string>("jwt_token");
	const { $sentry } = useNuxtApp();

	// Fetch user if possible
	if (jwtToken.value && !user.value) {
		try {
			await userStore.getUserInfo();
			await getCartData();
		} catch (error) {
			// Sentry error log
			$sentry?.captureException(`Fail to init user and cart data, JWT token: ${jwtToken.value}`);
		}
	}

	const isLoginRoute = ["/sign-in", "/sign-up"].some(r => to.path.includes(r));
	const isGuardedRoute = ["/my-cart", "/orders", "/my-account"].some(r => to.path.includes(r));

	if (!user.value) {
		if (isGuardedRoute) return navigateTo("/auth");
		else if (isLoginRoute) {
			const dest = to.path === "/sign-up" ? "/auth?tab=sign_up&accountType=business" : "/auth";
			return navigateTo(dest);
		} else return true;
	} else {
		if (isLoginRoute) return navigateTo("/");
		if (to.path !== "/my-cart") {
			try {
				await getCartData();
			} catch (err) {
				// Guest cart feature is not ready yet
			}
		}
		return true;
	}
});
