// create user related store
import { defineStore } from "pinia";

import type { LoginForm } from "@/composables/useURM";
import type { Organization } from "@/types/organizations";
import type { User } from "@/types/user";
import type { Address } from "~/types/addresses";

// create user store
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout } = useURM();
		const { /* viewOrganization, */ viewOrganizationFullDetailsOrganization } = useOrgMApi();
		const { userAuth } = useURMApi();
		const { getShippingAddressAddress } = useABMApi();
		const { $sentry } = useNuxtApp();

		// data
		const user = ref<User | null>();
		const userOrganization = ref<(Organization & { service_subscriptions: any[] }) | null>();
		const userShippingAddresses = ref<(Address & { continent?: string })[]>([]);

		const isLoggedIn = computed(() => !!user.value);

		const userOrgType = computed(() => {
			if (userOrganization.value?.service_subscriptions?.[0].service_id === "1f4f5c69-7a4f-428c-93be-2dfd6184ed56") {
				return "merchant";
			} else if (
				userOrganization.value?.service_subscriptions?.[0].service_id === "7fb8ec91-1278-43f6-9bd7-406bff94e4b9"
			) {
				return "Aggregator";
			} else {
				return "buyer";
			}

			return userOrganization.value;
		});

		const getShippingAddresses = async (id: string) => {
			try {
				// fetch shipping and billing addresses
				const { response, error } = await getShippingAddressAddress<{
					[key: string]: Address & { continent?: string };
				}>(id, "yes");

				if (error.value) throw error.value;

				if (response.value?.data) {
					const addressesArray = Object.keys(response.value.data).map(
						(itemKey: string) => response.value.data[itemKey]
					);

					return {
						shipping_addresses: addressesArray,
						main_shipping_address: addressesArray.find(address => address.main_shipping_address) || null,
					};
				}
			} catch (err: any) {
				if (err.statusCode !== 404) {
					$sentry.captureException(`Cannot fetch shipping addresses (${err.data?.error.message})`);
				}
			}

			return {
				shipping_addresses: [],
				main_shipping_address: null,
			};
		};

		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso = false) {
			if (sso === false) {
				return Promise.reject(new Error("Cannot login without SSO from this platform."));
			}
			try {
				const userInfo = await login(data, true);
				user.value = userInfo;
				if (user.value.organization_id) {
					await setUserOrganization(user.value.organization_id);
				}

				const response = await getShippingAddresses(user.value.organization_id || user.value.id);

				userShippingAddresses.value = response.shipping_addresses;
				user.value.main_shipping_address = response.main_shipping_address;

				return "ok";
			} catch (error: any) {
				if (error.data) {
					return Promise.reject(new Error(error.data.error.message));
				}
				return Promise.reject(error);
			}
		}

		// function for user logout
		async function userLogout() {
			await logout();
			sessionStorage.clear();
			user.value = null;
			userOrganization.value = null;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			const { response } = await userAuth();
			user.value = response.value?.data.user;

			if (user.value) {
				if (user.value?.organization_id) {
					await setUserOrganization(user.value.organization_id);
				}

				const response = await getShippingAddresses(user.value.organization_id || user.value.id);

				userShippingAddresses.value = response.shipping_addresses;
				user.value.main_shipping_address = response.main_shipping_address;

				return "Success";
			} else {
				Promise.reject(new Error("Failed to retrieve user info."));
			}
		}

		async function setUserOrganization(organization_id: string) {
			if (organization_id) {
				try {
					// Temporary change to allow MKTPLC to know the subscription for a user to see if they are from a merchant org
					// const { response, error } = (await viewOrganization(organization_id)) as Organization;
					const { response, error } = await viewOrganizationFullDetailsOrganization<
						Organization & { service_subscriptions: any[] }
					>(organization_id);
					if (error.value) throw error.value;

					if (response.value?.data) {
						userOrganization.value = response.value.data;
					}
				} catch (error) {
					if (user.value?.id) {
						$sentry.captureException(
							`Cannot fetch organization ${organization_id} for current user ${user.value.id}, ${error}`
						);
					} else {
						$sentry.captureException(`Cannot fetch organization ${organization_id} for guest user, ${error}`);
					}
				}
			}
		}

		return {
			user,
			isLoggedIn,
			userOrganization,
			userShippingAddresses,
			userOrgType,
			userLogin,
			userLogout,
			getUserInfo,
			setUserOrganization,
		};
	},
	{
		persist: {
			storage: piniaPluginPersistedstate.sessionStorage(),
		},
	}
);
